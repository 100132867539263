import React from 'react';
import { graphql } from 'gatsby';
import Layout from '~components/layouts/Default';
import PageHeader from '~components/common/PageHeader';
import Container from '~components/common/Container';
import GridThree from '~components/common/GridThree';
import BlogCard from '~components/common/BlogCard';

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Artikel zum entspannter werden und für mehr Gelassenheit" desc="Hier findest du alles zum Thema entspannter werden und zu Entspannungsübungen" active="Blog">
      <PageHeader title="Blog" breadcrumb={[{ title: 'Wissen', link: '/wissen/' }, { title: 'Blog' }]} />
      <Container noMargin className="my-8">
        <GridThree itemsStart>
          {data.allBlog.nodes.map((entry) => {
            return <BlogCard title={entry.titel} image={entry.remoteTitelbild} link={`/blog/${entry.slug_slug}/`} text={entry.einleitung} date={entry.datum} key={entry.id} fullText={entry.text} />;
          })}
        </GridThree>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query {
    allBlog(sort: { fields: datum, order: DESC }) {
      nodes {
        remoteTitelbild {
          childImageSharp {
            gatsbyImageData(quality: 80, placeholder: BLURRED)
          }
        }
        datum
        einleitung
        slug_slug
        text
        titel
        id
      }
    }
  }
`;
